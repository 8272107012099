import { useSelector } from "react-redux";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { columnSortChange } from "redux/searchResultsSlice";
import { getDisplayNameForFieldName } from "utilities/datafield";
import { recordsSortChange } from "utilities/matter";

// Column Header component for Search Results Data Grid
export default function DataGridColumnHeader({ resultField, columnIndex }) {
  const filters = useSelector((state) => state.simpleSearch?.currentSearch?.filters);
  const searchResults = useSelector((state) => state.searchResults).currentSearch;
  const dispatch = useDispatch();

  const sort = searchResults.sort;

  const onColumnClick = () => {
    let ascending = true;
    if (sort.column === resultField) ascending = !sort.ascending;
    dispatch(columnSortChange({ column: resultField, columnIndex: columnIndex, ascending }));
    recordsSortChange();
  };

  let matterTypeIds = null;
  if (filters) {
    const matterTypeFilter = filters.find((filter) => filter.fieldName === "matter_MatterTypeId");
    if (matterTypeFilter) {
      matterTypeIds = matterTypeFilter.valueArray;
    }
  }

  return (
    <th onClick={onColumnClick}>
      {getDisplayNameForFieldName(resultField, matterTypeIds, searchResults.abbreviated)}
      <div className="sort-spacer">
        {sort.column === resultField && (sort.ascending ? <FaCaretUp /> : <FaCaretDown />)}
      </div>
    </th>
  );
}
