import React from "react";
import BatchUpdateSummaryBar from "./BatchUpdateSummaryBar";
import BatchUpdateTable from "./BatchUpdateTable";
import BatchUpdateImageTable from "./BatchUpdateImageTable";
import BatchUpdateInsertTable from "./BatchUpdateInsertTable";
import BatchUpdateDocumentTable from "./BatchUpdateDocumentTable";

const BatchUpdateContainer = () => {
  return (
    <div className="batch-update__container">
      <div className="batch-update__content">
        <div className="batch-update__main">
          <BatchUpdateTable tableName="formFields" />
          <BatchUpdateTable tableName="companyLinks" />
          <BatchUpdateInsertTable tableName="commentInserts" />
          <BatchUpdateImageTable />
          <BatchUpdateDocumentTable />
        </div>
        <BatchUpdateSummaryBar />
      </div>
    </div>
  );
};

export default BatchUpdateContainer;
