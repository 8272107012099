import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Switch from "components/global/Switch";
import { t } from "locale/dictionary";

// Container Component for the display of a text field within the batch update system
function BatchUpdateFieldBoolean({ field, onChange }) {
  const [value, setValue] = useState(null);

  const controlId = `field-boolean-${field?.fieldName}`;

  useEffect(() => {
    setValue(field?.newValue || null);
  }, [field?.newValue]);

  const handleBooleanChange = (newValue) => {
    console.log("BatchUpdateFieldBoolean: handleBooleanChange: newValue", newValue);
    setValue(newValue);
    onChange(field, newValue);
  };

  return <Switch id={controlId} isOn={value} onSwitchChange={handleBooleanChange} text={t("No/Yes")} />;
}

BatchUpdateFieldBoolean.propTypes = {
  onChange: PropTypes.func,
  field: PropTypes.object,
};

export default BatchUpdateFieldBoolean;
