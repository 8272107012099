import React from "react";
import { useDispatch } from "react-redux";
import Checkbox from "components/global/Checkbox";
import { toggleAllExistingRowValuesChecked, toggleExistingRowValueChecked } from "redux/batchUpdateSlice";
import { getCountryNameFromIsoCode } from "utilities/countries";
import { labelTypes } from "utilities/constants";
import { t } from "locale/dictionary";
import { getListDisplayValue, getListTypeByCode } from "utilities/lookup";
import PanelFieldCompanyAddress from "../matter-record/field-display-types/PanelFieldCompanyAddress";
import { getDataTypeForField, getLookupValueForField } from "utilities/datafield";
import { getDateDisplayValue } from "utilities/dateTime";

const BatchUpdateExistingValues = ({ rowItem, tableName, existingValues }) => {
  const dispatch = useDispatch();

  if (!existingValues || rowItem.isExpanded === false) {
    return <div>Expand this field to view</div>;
  }

  const renderExistingValue = (ev) => {
    let displayValue = "";
    let evValue = null;
    if (tableName === "formFields") {
      evValue = ev.value;
      displayValue = evValue;
      const displayType = getDataTypeForField(rowItem.fieldName);
      if (displayType === labelTypes.LOOKUP) displayValue = getLookupValueForField(rowItem.fieldName, evValue);
      if (displayType === labelTypes.DATE) displayValue = getDateDisplayValue(evValue);
      if (!ev.value || ev.value === "") displayValue = t("{Empty Value}");
      if (displayType === labelTypes.BOOLEAN) {
        displayValue = "Not set";
        if (evValue === true) displayValue = "Yes";
        if (evValue === false) displayValue = "No";
      }
      if (rowItem.isListType) {
        displayValue = getListDisplayValue(getListTypeByCode(rowItem.fieldName)?.id, evValue);
      }
    } else if (tableName === "companyLinks") {
      evValue = ev.matterId;
      displayValue = ev && (
        <PanelFieldCompanyAddress
          keyProps={{
            rowData: {
              matterCompanyLink_Address1: ev.address1,
              matterCompanyLink_Address2: ev.address2,
              matterCompanyLink_Address3: ev.address3,
              matterCompanyLink_City: ev.city,
              matterCompanyLink_State: ev.state,
              matterCompanyLink_PostalCode: ev.postalCode,
            },
          }}
        />
      );
    }

    return (
      <div className="option-container" key={evValue}>
        <Checkbox
          isChecked={ev.isChecked}
          onCheckChange={() => dispatch(toggleExistingRowValueChecked({ rowItem, value: evValue, tableName }))}
        />
        <div className="option-texts">
          <div className="option">
            {tableName === "companyLinks" ? (
              <>
                {ev.name}:&nbsp;{displayValue}&nbsp;{getCountryNameFromIsoCode(ev.countryId)}&nbsp;({ev.count})
              </>
            ) : (
              <span className="option option--no-match">{`${displayValue} (${ev.count})`}</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="td__existing-values">
      {existingValues.length > 1 && (
        <div className="option-container">
          <Checkbox
            isChecked={existingValues.every((fv) => fv.isChecked)}
            onCheckChange={() => dispatch(toggleAllExistingRowValuesChecked({ rowItem, tableName }))}
          />
          <div className="option-texts">
            <div className="option">
              <span className="option option--no-match">
                <strong>Select All</strong>
              </span>
            </div>
          </div>
        </div>
      )}
      {existingValues.map((ev) => renderExistingValue(ev))}
    </div>
  );
};

export default BatchUpdateExistingValues;
