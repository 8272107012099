import { search } from "api/search";
import { t } from "locale/dictionary";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDateDisplayValue, stripTimeFromDate, toISOStringWithOffset } from "utilities/dateTime";
import { add } from "date-fns";
import { FormatTypes } from "utilities/constants";
import { parseSearchResponse, transformSearchResultData } from "utilities/searchResults";
import { getDisplayNameForFieldName } from "utilities/datafield";
import { useNavigate } from "react-router";
import ReactDatePicker from "react-datepicker";
import { compareByFieldValue } from "utilities/stringAndArray";
import { labelTypes } from "utilities/constants";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";

// Displays date ranged set of actions for the attention of current user
export default function MyDiary() {
  const bearerToken = useSelector((state) => state.app.bearerToken);
  const dataFields = useSelector((state) => state.dataField).dataFields;

  const navigate = useNavigate();

  const [tableKey, setTableKey] = useState(null);
  const [headerCols, setHeaderCols] = useState([]);
  const [diaryEntries, setDiaryEntries] = useState([]);
  const [dates, setDates] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);
  const [sortCol, setSortCol] = useState(null);

  const fieldNames = {
    actionDate: "matterAction_ActionDate",
    actionId: "matterAction_MatterActionId",
    actionTypeId: "matterAction_ActionTypeId",
    actionTypeTranslatedName: "matterAction_ActionTypeTranslatedName",
    matterName: "matter_Name",
  };

  useEffect(() => {
    if (bearerToken && dataFields.length > 0) {
      const start = new Date();
      const end = add(start, { days: 30 });
      if (!dates) setDates({ start, end });
    }
  }, [bearerToken, dataFields]);

  useEffect(() => {
    if (!dates || !dates.start || !dates.end) return;
    const start = toISOStringWithOffset(dates.start);
    const end = toISOStringWithOffset(dates.end);

    const filters = [{ fieldName: fieldNames.actionDate, operator: "between", valueArray: [start, end] }];
    const sortColumns = [{ fieldName: fieldNames.actionDate }, { fieldName: fieldNames.actionTypeId }];

    const queryObject = {
      queryType: 3,
      formatType: FormatTypes.LIST,
      searchFilters: filters,
      sortColumns,
    };

    search(queryObject).then((response) => {
      setTableKey(response.logId);
      const headerColsLocal = response.header.dataFieldNames;
      setHeaderCols(headerColsLocal);
      const cols = parseSearchResponse(response);
      const [transformedData, linkTypes] = transformSearchResultData(cols);

      let diaryEntriesLocal = transformedData.map((row) => {
        return {
          matterAction_MatterActionId: row.key,
          matterKey: row.matterKey,
          matterAction_ActionDate: stripTimeFromDate(row.rowData[headerColsLocal.indexOf(fieldNames.actionDate)]),
          matterAction_ActionTypeTranslatedName: row.rowData[headerColsLocal.indexOf(fieldNames.actionTypeId)],
          matter_Name: row.rowData[headerColsLocal.indexOf(fieldNames.matterName)],
        };
      });
      diaryEntriesLocal = sortColumn(fieldNames.matterName, diaryEntriesLocal, true);
      diaryEntriesLocal = sortColumn(fieldNames.actionTypeTranslatedName, diaryEntriesLocal, true);
      diaryEntriesLocal = sortColumn(fieldNames.actionDate, diaryEntriesLocal, true);
      setSortCol(fieldNames.actionDate);
      setDiaryEntries(diaryEntriesLocal);
    });
  }, [dates]);

  const sortColumn = (fieldName, diaryEntriesToSort, isInitialSort) => {
    let fieldType = labelTypes.STRING;
    if (fieldName === fieldNames.actionDate) fieldType = labelTypes.DATE;
    if (fieldName === fieldNames.actionTypeTranslatedName) fieldType = labelTypes.LOOKUP;
    let newSortAsc = true;
    if (!isInitialSort) newSortAsc = fieldName === sortCol ? !sortAsc : true;
    diaryEntriesToSort.sort((a, b) => compareByFieldValue(a, b, fieldName, fieldType, newSortAsc));
    if (!isInitialSort) {
      setSortAsc(newSortAsc);
      setSortCol(fieldName);
      setDiaryEntries(diaryEntriesToSort);
    }
    return diaryEntriesToSort;
  };

  const onNavigateToMatter = (id) => {
    navigate(`/matter/${id}/Actions`, { replace: false }); // NOTE: "Actions" is case-sensitive (matches on elementid containing this string)
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setDates({ start, end });
  };

  const handleColumnClick = (fieldName) => {
    const diaryEntriesLocal = [...diaryEntries];
    sortColumn(fieldName, diaryEntriesLocal);
  };

  const renderHeaderCol = (fieldName, displayFieldName) => {
    return (
      <th key={fieldName} onClick={() => handleColumnClick(fieldName)}>
        {getDisplayNameForFieldName(displayFieldName ?? fieldName)}
        <div className="sort-spacer">
          {fieldName === sortCol && <span>{sortAsc ? <FaCaretUp /> : <FaCaretDown />}</span>}
        </div>
      </th>
    );
  };

  const renderHeader = (
    <tr>
      {renderHeaderCol(fieldNames.actionDate)}
      {renderHeaderCol(fieldNames.actionTypeTranslatedName, fieldNames.actionTypeId)}
      {renderHeaderCol(fieldNames.matterName)}
    </tr>
  );

  const renderDiaryEntries = diaryEntries.map((diaryEntry, index) => {
    return (
      diaryEntry && (
        <tr key={index} onClick={() => onNavigateToMatter(diaryEntry.matterKey)}>
          <td className="data-grid__cell data-grid__cell--20">
            {getDateDisplayValue(diaryEntry[fieldNames.actionDate])}
          </td>
          <td className="data-grid__cell data-grid__cell--40">{diaryEntry[fieldNames.actionTypeTranslatedName]}</td>
          <td className="data-grid__cell data-grid__cell--40">{diaryEntry[fieldNames.matterName]}</td>
        </tr>
      )
    );
  });

  return (
    <>
      {dates && (
        <div className="flex-row-center">
          <ReactDatePicker
            selected={dates.start}
            onChange={handleDateChange}
            startDate={dates.start}
            endDate={dates.end}
            selectsRange
            inline
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
      )}
      {diaryEntries.length > 0 && headerCols.length > 0 ? (
        <div className="search-results__data-grid">
          <table key={tableKey}>
            <thead>{renderHeader}</thead>
            <tbody>{renderDiaryEntries}</tbody>
          </table>
        </div>
      ) : (
        <p>{t("No entries")}</p>
      )}
    </>
  );
}
