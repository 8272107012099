import { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getCountryCodeAlpha2FromIsoCode } from "utilities/countries";
import { hasMatchingId, idsAreEqual } from "utilities/stringAndArray";
import { getMatterContactsExtended } from "api/contact";
import { getMatterCompaniesWithAccountId } from "api/company";
import SuperSelect from "components/global/SuperSelect";
import { t } from "locale/dictionary";
import BatchUpdateContactEditor from "./BatchUpdateContactEditor";
import { getContactsByRoleDisplay } from "utilities/contacts";
import { clearContactLinks } from "redux/batchUpdateSlice";

// Container Component for the display of a name search within the batch update system
function BatchUpdateFieldNameSearch({ linkType, onChange, existingIdsForLinkType, isContactNameSearch }) {
  const appState = useSelector((state) => state.app);
  const dispatch = useDispatch();
  //const locale = useSelector((state) => state.locale);

  const [value, setValue] = useState("");

  const [isChanged, setIsChanged] = useState(false);
  const [suggestedResultsObjectList, setSuggestedResultsObjectList] = useState([]);
  const [suggestedResultsDisplayList, setSuggestedResultsDisplayList] = useState([]);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [showContactSelectionPopup, setShowContactSelectionPopup] = useState(false);

  const controlId = `field-company-name-search-${linkType?.id}`;

  // useEffect(() => {
  //   if (isInitialValueLoad && initialSearchValue) {
  //     onTextChange(initialSearchValue);
  //     setIsChanged(false);
  //   }
  //   setIsInitialValueLoad(false);
  // }, [keyProps.destinationMatterId, initialSearchValue, refreshSuggestions]);

  // console.log(selectedOptionId, typeof selectedOptionId);
  // console.log(suggestedResultsDisplayList.map((result) => `${result.id}, ${typeof result.id}`));

  const formatDisplayList = (response, isContact) => {
    return response.map((entity) => {
      const id = entity.matterId;
      const displayValue = isContact
        ? `${entity.givenName} ${entity.familyName} (${entity.companyName})`
        : `${entity.name ?? ""} - ${entity.city ? `${entity.city},` : ""} ${entity.state ? `${entity.state},` : ""} ${
            getCountryCodeAlpha2FromIsoCode(entity.countryId) ?? ""
          }`;
      return { id, displayValue };
    });
  };

  const onTextChange = (newValue) => {
    const handleNameSearchResponse = (response, isContact) => {
      // filter out existing links
      let filteredResponse = response;
      if (existingIdsForLinkType && existingIdsForLinkType.length > 0) {
        filteredResponse = response.filter((result) => !hasMatchingId(existingIdsForLinkType, result.matterId));
      }
      setSuggestedResultsObjectList(filteredResponse);
      const displayList = formatDisplayList(filteredResponse, isContact);
      setSuggestedResultsDisplayList(displayList);
    };

    if (newValue === value && isChanged) setIsChanged(false);
    if (newValue !== value && !isChanged) setIsChanged(true);

    setValue(newValue);
    if (newValue.length < 3) {
      setSuggestedResultsObjectList([]);
      setSuggestedResultsDisplayList([]);
      setSelectedOptionId(null);
      return;
    }

    // if (!searchOnRecord) {
    //   getMatterCompaniesFromSearchTerms(keyProps.record.customerId, newValue).then((response) => {
    //     handleNameSearchResponse(response);
    //   });
    //   return;
    // }

    if (isContactNameSearch) {
      getMatterContactsExtended(appState.customerId, appState.accountId, newValue).then((response) => {
        handleNameSearchResponse(response, true);
      });
    } else {
      getMatterCompaniesWithAccountId(appState.customerId, appState.accountId, newValue).then((response) => {
        handleNameSearchResponse(response);
      });
    }
  };

  const onSetSelectedResult = async (id) => {
    onChange(
      linkType,
      suggestedResultsObjectList.find((item) => item.matterId === id)
    );
    setSelectedOptionId(id);
    dispatch(clearContactLinks({ parentRowItem: linkType, tableName: "companyLinks" }));
  };
  let placeholderText = `Search for ${isContactNameSearch ? "people" : "companies"} here`;

  const renderContactArea = () => {
    return (
      <>
        <BatchUpdateContactEditor
          tableName="companyLinks"
          parentRowItem={linkType}
          parentId={selectedOptionId}
          parentName={suggestedResultsDisplayList.find((result) => result.id === selectedOptionId)?.displayValue}
          isShowingPopup={showContactSelectionPopup}
          onClose={() => setShowContactSelectionPopup(false)}
        />
        <button onClick={() => setShowContactSelectionPopup(true)}>{t("Add Contacts")}</button>
      </>
    );
  };

  return (
    <div className="td__edit-field-container td__edit-field-container--name-search">
      <SuperSelect
        id={controlId}
        options={[...suggestedResultsDisplayList]}
        selectedOptionId={selectedOptionId}
        onChange={onSetSelectedResult}
        tabIndex={0}
        onTextChangeUpdateList={onTextChange}
        placeholderText={placeholderText}
      />
      {selectedOptionId && renderContactArea()}
    </div>
  );
}

BatchUpdateFieldNameSearch.propTypes = {
  linkType: PropTypes.object,
  existingIdsForLinkType: PropTypes.array,
  isContactNameSearch: PropTypes.bool,
  onChange: PropTypes.func,
};

export default BatchUpdateFieldNameSearch;
