import PropTypes from "prop-types";
import { dndTypes } from "utilities/constants";
import { useDrop } from "react-dnd";
import { useSelector } from "react-redux";

// Container Component for the interstice (gap between) thumbnails
function BatchUpdateImageInterstice({ imageId }) {
  const images = useSelector((state) => state.batchUpdate).imageInserts;
  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: dndTypes.THUMBNAIL,
      drop: () => ({ droppedBeforeImageId: imageId }),
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    [images]
  );

  const classNameBase = "thumbnail-list__interstice";
  const className = isOver ? `${classNameBase} ${classNameBase}--over` : classNameBase;

  return (
    <div ref={drop} className={className}>
      &nbsp;
    </div>
  );
}

BatchUpdateImageInterstice.propTypes = {
  imageId: PropTypes.number,
};

export default BatchUpdateImageInterstice;
