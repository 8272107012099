import { useState } from "react";
import PropTypes from "prop-types";
import { useEffect } from "react";

// Container Component for the display of a text field within the batch update system
function BatchUpdateFieldText({ field, rowItem, onChange }) {
  const [value, setValue] = useState("");

  const controlId = `field-text-${field?.fieldName}-${rowItem?.id}`;

  useEffect(() => {
    setValue(field?.newValue || (rowItem && rowItem[field?.fieldName]));
  }, [field?.newValue, rowItem]);

  const handleTextChange = (newValue) => {
    setValue(newValue);
  };

  return field?.isReadOnly ? (
    <div className="td__edit-field-container td__edit-field-container--readonly">{value}</div>
  ) : (
    <div className="td__edit-field-container">
      <input
        id={controlId}
        type="text"
        value={value}
        onChange={(e) => handleTextChange(e.target.value)}
        onBlur={(e) => onChange(field, value)}
        tabIndex={0}
        autoComplete="off"
      />
    </div>
  );
}

BatchUpdateFieldText.propTypes = {
  rowItem: PropTypes.object,
  onChange: PropTypes.func,
  field: PropTypes.object,
};

export default BatchUpdateFieldText;
