import { t } from "locale/dictionary";
import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getLookupValueForField } from "utilities/datafield";
import BatchUpdateSubmission from "./BatchUpdateSubmission";

const BatchUpdateSummaryBar = () => {
  const batchUpdateState = useSelector((state) => state.batchUpdate);
  const searchResultsState = useSelector((state) => state.searchResults.currentSearch);

  const [batchByMatterId, setBatchByMatterId] = useState([]);
  const [showSubmitConfirmation, setShowSubmitConfirmation] = useState(false);

  useEffect(() => {
    // Collate from searchResultsState.renderData by the matterTypeId property in that array, and prepare an array showing how many records are in what matterTypeId
    const selectedSearchResults = searchResultsState.renderData.filter((rd) => {
      return batchUpdateState.recordIds.includes(rd.key);
    });
    const counts = {};

    selectedSearchResults.forEach((item) => {
      const matterTypeId = item.matterTypeId;
      if (counts[matterTypeId]) {
        counts[matterTypeId]++;
      } else {
        counts[matterTypeId] = 1;
      }
    });

    const batchByMatterIdLocal = Object.keys(counts).map((matterTypeId) => ({
      matterTypeId,
      count: counts[matterTypeId],
    }));

    setBatchByMatterId(batchByMatterIdLocal);
  }, [batchUpdateState?.recordIds, searchResultsState?.renderData]);

  const handleBatchUpdateSubmit = () => {
    setShowSubmitConfirmation(true);
  };

  return (
    <>
      <div className="batch-update__summary">
        <div className="batch-update__header">{t("Batch Update")}</div>
        <ul className="summary__records-list">
          <div className="records-list__intro">Currently working on:</div>
          {batchByMatterId.map((item) => (
            <li key={item.matterTypeId} className="batch-update__summary__item">
              {`${getLookupValueForField("matter_MatterTypeId", item.matterTypeId)} (${item.count})`}
            </li>
          ))}
        </ul>
        <button onClick={handleBatchUpdateSubmit}>{t("Submit")}</button>
      </div>
      {showSubmitConfirmation && <BatchUpdateSubmission onClose={() => setShowSubmitConfirmation(false)} />}
    </>
  );
};

export default BatchUpdateSummaryBar;
