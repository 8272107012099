import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from "components/global/DatePicker";

// Container Component for the display of a text field within the batch update system
function BatchUpdateFieldDate({ field, rowItem, onChange }) {
  const [value, setValue] = useState(null);

  const controlId = `field-date-${field?.fieldName}-${rowItem?.id}`;

  useEffect(() => {
    setValue(field?.newValue || (rowItem && rowItem[field?.fieldName]));
  }, [field?.newValue, rowItem]);

  const handleDateChange = (newValue) => {
    setValue(newValue);
    onChange(field, newValue);
  };

  return (
    <div className="td__edit-field-container">
      <DatePicker id={controlId} selected={value} onDateChange={handleDateChange} />
    </div>
  );
}

BatchUpdateFieldDate.propTypes = {
  rowItem: PropTypes.object,
  onChange: PropTypes.func,
  field: PropTypes.object,
};

export default BatchUpdateFieldDate;
