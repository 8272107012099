import PropTypes from "prop-types";
import { FaTimesCircle } from "react-icons/fa";
import { dndTypes } from "utilities/constants";
import { useDrag } from "react-dnd";
import { useEffect, useState } from "react";
import Delete from "components/global/Delete";
import Tooltip from "components/global/Tooltip";
import { t } from "locale/dictionary";
import { useDispatch, useSelector } from "react-redux";
import { deleteImage, updateImageDescription } from "redux/batchUpdateSlice";

// Container Component for the image thumbnail within the images panel table
function BatchUpdateImageThumbnail({ image, onDrop, onChange, onDelete }) {
  const images = useSelector((state) => state.batchUpdate).imageInserts;

  const dispatch = useDispatch();

  const [description, setDescription] = useState(image.matterImage_Description ?? "");
  const [isChanged, setIsChanged] = useState(false);

  /* eslint-disable */
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: dndTypes.THUMBNAIL,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (dropResult) onDrop(image.matterImage_ImageId, dropResult.droppedBeforeImageId);
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [images]
  );

  useEffect(() => {
    setDescription(image.matterImage_Description ?? "");
  }, [image.matterImage_Description]);

  const onDescriptionChange = (e) => {
    const newDescription = e.target.value;
    if (newDescription === description && isChanged) setIsChanged(false);
    if (newDescription !== description && !isChanged) setIsChanged(true);

    setDescription(newDescription);
  };

  const saveDescriptionChanges = () => {
    if (description !== image.matterImage_Description)
      dispatch(updateImageDescription({ imageId: image.matterImage_ImageId, description }));
    setIsChanged(false);
  };

  const handleDelete = () => {
    dispatch(deleteImage(image.matterImage_ImageId));
  };

  const secondaryInfoText = `${t("Filename")}: ${image.matterImage_Filename}, ${t("File size")}: ${
    image.matterImage_ByteCount
  } ${t("bytes")}`;

  return (
    <>
      <div className="thumbnail">
        <div ref={drag} className="thumbnail__body">
          <img src={`data:image;base64,${image.matterImage_Base64}`} alt={image.matterImage_Filename} />
          {/* {image.matterImage_SortIndex ?? "NULL"} */}
          <div className="body__overlay">
            <div className="overlay__items">
              <div className="overlay__item">
                <Delete message={`Remove "${image.matterImage_Description}" from the list`} onConfirm={handleDelete} />
                <FaTimesCircle />
              </div>
            </div>
          </div>
        </div>
        <Tooltip content={secondaryInfoText}>
          <div className="thumbnail__description">
            <input
              value={description}
              onChange={onDescriptionChange}
              onBlur={saveDescriptionChanges}
              autoComplete="off"
            />
          </div>
        </Tooltip>
      </div>
    </>
  );
}

BatchUpdateImageThumbnail.propTypes = {
  image: PropTypes.object,
  onDrop: PropTypes.func,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default BatchUpdateImageThumbnail;
