//import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateDocumentSortIndex } from "redux/batchUpdateSlice";
import { t } from "locale/dictionary";
import BatchUpdateFileUploader from "./BatchUpdateFileUploader";
import { sectionDefinitions } from "utilities/sectionDefinitions";
import { getDisplayNameForField } from "utilities/reportEditor";
import BatchUpdateInsertTableRow from "./BatchUpdateInsertTableRow";

function BatchUpdateDocumentTable() {
  const batchUpdateDocuments = useSelector((state) => state.batchUpdate).documentInserts;

  const [section, setSection] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setSection(sectionDefinitions.find((sd) => sd.tableName === "documents"));
  }, []);

  const handleAddNewDocument = () => {
    // Create a new row with an id that is unique
    // const newRow = {
    //   id: Math.random().toString(36),
    //   ...section.fieldList.reduce((acc, field) => {
    //     acc[field.fieldName] = null; // Add each field name as a property with a null value
    //     if (field.fieldName === "matterComment_CreatedBy") {
    //       acc[field.fieldName] = appState.userId;
    //     }
    //     return acc;
    //   }, {}),
    // };
    // dispatch(addInsertRow({ tableName, newRow }));
  };

  const renderTableHeader = () => {
    return (
      <tr>
        {section?.fieldList
          ?.filter((field) => !field.isHiddenWhenAdding)
          ?.map((field) => (
            <th key={field?.fieldName}>{getDisplayNameForField(field?.fieldName)}</th>
          ))}
        <th></th>
      </tr>
    );
  };

  return (
    <div className="batch-update__table-container">
      <>
        <div className="batch-update__table-title">{t("Documents")}</div>
        <BatchUpdateFileUploader onAddNew={handleAddNewDocument} />
        {/* <div className="batch-update__table-document-container"> */}
        <table className="batch-update__table">
          <thead>{renderTableHeader()}</thead>
          <tbody>
            {batchUpdateDocuments?.map((documentRow) => (
              <BatchUpdateInsertTableRow
                key={documentRow.id}
                insertRow={documentRow}
                tableName="documentInserts"
                section={section}
              />
            ))}
          </tbody>
        </table>
        {/* </div> */}
      </>
    </div>
  );
}

export default BatchUpdateDocumentTable;
