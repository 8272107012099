//import PropTypes from "prop-types";
import FileUploader from "components/global/FileUploader";
import { compareByMatterImageSortIndex, findById, idsAreEqual } from "utilities/stringAndArray";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BatchUpdateImageInterstice from "./BatchUpdateImageInterstice";
import BatchUpdateImageThumbnail from "./BatchUpdateImageThumbnail";
import { updateImageSortIndex } from "redux/batchUpdateSlice";
import { t } from "locale/dictionary";
import BatchUpdateFileUploader from "./BatchUpdateFileUploader";

// Container Component for the table type data panel for a matter keyProps.record
function BatchUpdateImageTable() {
  const [imagesSorted, setImagesSorted] = useState(null);
  const batchUpdateImages = useSelector((state) => state.batchUpdate).imageInserts;

  const dispatch = useDispatch();

  useEffect(() => {
    if (batchUpdateImages) {
      let imagesTemp = [...batchUpdateImages];
      const imagesSortedTemp = imagesTemp.sort(compareByMatterImageSortIndex);
      setImagesSorted(imagesSortedTemp);
    }
  }, [batchUpdateImages]);

  const onDrop = (imageBeingDroppedId, droppedBeforeImageId) => {
    let images = [...batchUpdateImages];
    const imageBeingDropped = images.find((image) => idsAreEqual(image.matterImage_ImageId, imageBeingDroppedId));
    let droppedBeforeSortIndex = null;
    if (droppedBeforeImageId) {
      const droppedBeforeImage = images.find((image) => idsAreEqual(image.matterImage_ImageId, droppedBeforeImageId));
      droppedBeforeSortIndex = droppedBeforeImage.matterImage_SortIndex;
    } else {
      droppedBeforeSortIndex = batchUpdateImages.length + 1;
    }
    const beingDroppedSortIndex = imageBeingDropped.matterImage_SortIndex;
    const newSortIndexPrimary =
      beingDroppedSortIndex > droppedBeforeSortIndex ? droppedBeforeSortIndex : droppedBeforeSortIndex - 1;
    // Update sortIndex of dropped image
    dispatch(
      updateImageSortIndex({
        imageId: imageBeingDropped.matterImage_ImageId,
        newSortIndex: newSortIndexPrimary,
        existingSortIndex: beingDroppedSortIndex,
      })
    );

    // Update sortIndices of images in the order after dropped image

    let imagesAfterDrop =
      beingDroppedSortIndex > droppedBeforeSortIndex
        ? images.filter(
            (image) =>
              image.matterImage_SortIndex >= droppedBeforeSortIndex &&
              image.matterImage_SortIndex < beingDroppedSortIndex
          )
        : images.filter(
            (image) =>
              image.matterImage_SortIndex < droppedBeforeSortIndex &&
              image.matterImage_SortIndex > beingDroppedSortIndex
          );
    imagesAfterDrop.forEach((image) => {
      const newSortIndex =
        beingDroppedSortIndex > droppedBeforeSortIndex
          ? image.matterImage_SortIndex + 1
          : image.matterImage_SortIndex - 1;
      dispatch(
        updateImageSortIndex({
          imageId: image.matterImage_ImageId,
          newSortIndex,
          existingSortIndex: image.matterImage_SortIndex,
        })
      );
    });
  };

  const handleAddNewImage = (acceptedFiles) => {};

  const handleImageChange = (field, imageId, value) => {
    //saveImageFieldChanges(imageId, field, value);
  };

  const handleImageDelete = (imageId) => {
    //deleteImage(imageId);
  };

  const renderThumbnailList =
    imagesSorted &&
    imagesSorted.map((image) => {
      return (
        <div className="flex-row-center" key={image.matterImage_ImageId}>
          <BatchUpdateImageInterstice imageId={image.matterImage_ImageId} />
          <BatchUpdateImageThumbnail
            image={image}
            onDrop={onDrop}
            onChange={handleImageChange}
            onDelete={handleImageDelete}
          />
        </div>
      );
    });

  return (
    <>
      <div className="batch-update__table-title">{t("Images")}</div>
      <div className="data-panel__table">
        <BatchUpdateFileUploader isImage={true} onAddNew={handleAddNewImage} />
        <div className="thumbnail-list">
          {renderThumbnailList}
          <BatchUpdateImageInterstice />
        </div>
      </div>
    </>
  );
}

// PanelThumbnails.propTypes = {
//   keyProps: PropTypes.object,
//   onAddNew: PropTypes.func,
//   onChange: PropTypes.func,
//   onDelete: PropTypes.func,
// };

export default BatchUpdateImageTable;
