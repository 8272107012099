// Individual Matter related API calls here
import {
  addChildTableLink,
  addNewChildObject,
  deleteChildTableLink,
  deleteChildObjectRow,
  setRequiredMatterData,
  updateChildTableLink,
  updateChildObjectFieldValue,
  updateMatterCountries,
  updateMatterListValues,
  updateMatterReference,
  updateSimpleFieldValue,
  setSuggestedMatterData,
  setDefaultMatterData,
  setRecords,
  clearAllRecords,
} from "redux/matterSlice";
import store from "redux/store";
import { isDate, toISOStringWithOffset } from "utilities/dateTime";

import { fetchWrapper } from "utilities/fetchWrapper";
import { getMatterContactfromId } from "./contact";
import { clearSelected } from "redux/searchResultsSlice";

// Get the global list of required fields across all matter types
export async function getRequiredMatterData() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/requiredmatterdata/`);
  store.dispatch(setRequiredMatterData(response));
}

// Get the global list of suggested fields for new records
export async function getSuggestedMatterData() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/suggestedmatterdata/`);
  store.dispatch(setSuggestedMatterData(response));
}

// Get the global list of auto-fill values for new dropdown lists
export async function getDefaultMatterData() {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/defaultmatterdata/`);
  store.dispatch(setDefaultMatterData(response));
}

// Get the main fully-expanded set of data relating to a matter record
export async function getMatterById(matterId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.get(`customers/${customerId}/matters/${matterId}`);
  return response;
}

export async function createMatterRecord(matter_matterTypeId, matter_Name) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = { matter_Name: matter_Name ?? "", matter_AccountId: state.app.accountId, matter_matterTypeId };
  const response = await fetchWrapper.post(`customers/${customerId}/matters`, body);
  return response;
}
export async function copyMatterRecord(body) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.post(`customers/${customerId}/matters`, body);
  return response;
}

export async function deleteMatterRecord(matterId) {
  const state = store.getState();
  const customerId = state.app.customerId;
  await fetchWrapper.delete(`customers/${customerId}/matters/${matterId}`);
}

/*************************************************
      SIMPLE FIELD CHANGES (FORM AND NOT TABLE)
**************************************************/

// Save changes to a "simple" data field within a matter record (i.e. not within a table)
export async function saveMatterSimpleFieldChanges(matterId, fieldName, value, initialValue) {
  // If no material change in value, do not save
  if (value === initialValue || (!value && initialValue === "") || (value === "" && !initialValue)) return;

  const state = store.getState();
  const customerId = state.app.customerId;

  if (isDate(value)) {
    value = toISOStringWithOffset(value);
  }
  if (isDate(initialValue)) {
    initialValue = toISOStringWithOffset(initialValue);
  }

  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(`customers/${customerId}/matters/${matterId}`, body);

  store.dispatch(updateSimpleFieldValue({ matterId, fieldName, tableName: null, value }));
  //displayToast(`Updated simple field "${fieldName}"`, severities.INFO);

  updateOnNewRecordChange();
}

export async function saveMatterReferenceChanges(matterId, referenceTypeId, value, initialValue) {
  // If no material change in value, do not save
  if (value === initialValue || (!value && initialValue === "") || (value === "" && !initialValue)) return;

  const state = store.getState();
  const customerId = state.app.customerId;

  const body = [
    { path: "/matterReference_ReferenceNumber", op: "test", value: initialValue },
    { path: "/matterReference_ReferenceNumber", op: "replace", value },
  ];

  await fetchWrapper.patch(`customers/${customerId}/matters/${matterId}/references/${referenceTypeId}`, body);

  store.dispatch(updateMatterReference({ matterId, referenceTypeId, referenceNumber: value }));
}

/****************************************
      TOP-LEVEL TABLE ROW CHANGES
******************************************/

// Add new child object (row with data) to a table
export async function saveNewChildObject(matterId, fieldNamesToValidate, tableName, body, isCopying) {
  if (tableName === "legalTeamContactLinks" && !body?.matterLegalTeamContactLink_MatterContactId) return;
  const state = store.getState();
  const customerId = state.app.customerId;
  const response = await fetchWrapper.post(`customers/${customerId}/matters/${matterId}/${tableName}`, body);
  if (isCopying) return;
  store.dispatch(addNewChildObject({ matterId, fieldNamesToValidate, tableName, newObject: response }));
  return response;
}

// Save changes to a "child object" (i.e. a field within a row within a table such as actions or comments)
export async function saveMatterChildObjectFieldChanges(
  matterId,
  tableName,
  rowId,
  fieldName,
  value,
  initialValue,
  contactMatterId
) {
  // If no material change in value, do not save
  if (value === initialValue || (!value && initialValue === "") || (value === "" && !initialValue)) return;

  const state = store.getState();
  const customerId = state.app.customerId;

  if (isDate(value)) {
    value = toISOStringWithOffset(value);
  }
  if (isDate(initialValue)) {
    initialValue = toISOStringWithOffset(initialValue);
  }

  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];

  await fetchWrapper.patch(`customers/${customerId}/matters/${matterId}/${tableName}/${rowId}`, body);
  store.dispatch(updateChildObjectFieldValue({ matterId, tableName, rowId, fieldName, value }));

  if (fieldName === "matterLegalTeamContactLink_MatterContactId") {
    const matterContact = await getMatterContactfromId(contactMatterId, value);
    const email = matterContact.matterContact_Email;
    store.dispatch(
      updateChildObjectFieldValue({
        matterId,
        tableName,
        rowId,
        fieldName: "matterLegalTeamContactLink_Email",
        value: email,
      })
    );
  }
}

// Delete a child object (row with data) from a table
export async function deleteChildObject(matterId, fieldNamesToValidate, tableName, rowId) {
  const state = store.getState();
  const customerId = state.app.customerId;

  const response = await fetchWrapper.delete(`customers/${customerId}/matters/${matterId}/${tableName}/${rowId}`);

  store.dispatch(deleteChildObjectRow({ matterId, fieldNamesToValidate, tableName, rowId }));
  return response;
}

/******************************************************
    SECOND-LEVEL ROW CHANGES (TABLE WITHIN A TABLE)
*******************************************************/

// Add a new row to a sub-table
export async function addMatterChildTableLink(
  matterId,
  fieldNamesToValidate,
  parentTableName,
  parentRowId,
  childTableName,
  newObject
) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = newObject;
  const newChildObject = await fetchWrapper.post(
    `customers/${customerId}/matters/${matterId}/${parentTableName}/${parentRowId}/${childTableName}`,
    body
  );

  store.dispatch(
    addChildTableLink({ matterId, fieldNamesToValidate, parentTableName, parentRowId, childTableName, newChildObject })
  );
}

// Save changes to a row within a child table
export async function saveMatterChildTableLinksChanges(
  matterId,
  parentTableName,
  parentRowId,
  childTableName,
  childLinkId,
  fieldName,
  value,
  initialValue
) {
  // If no material change in value, do not save
  if (value === initialValue || (!value && initialValue === "") || (value === "" && !initialValue)) return;

  const state = store.getState();
  const customerId = state.app.customerId;
  const body = [
    { path: `/${fieldName}`, op: "test", value: initialValue },
    { path: `/${fieldName}`, op: "replace", value },
  ];
  await fetchWrapper.patch(
    `customers/${customerId}/matters/${matterId}/${parentTableName}/${parentRowId}/${childTableName}/${childLinkId}`,
    body
  );

  store.dispatch(
    updateChildTableLink({ matterId, parentTableName, parentRowId, childTableName, childLinkId, fieldName, value })
  );
}

// Remove a row from a child table
export async function deleteMatterChildTableLink(
  matterId,
  fieldNamesToValidate,
  parentTableName,
  parentRowId,
  childTableName,
  childLinkId
) {
  const state = store.getState();
  const customerId = state.app.customerId;
  await fetchWrapper.delete(
    `customers/${customerId}/matters/${matterId}/${parentTableName}/${parentRowId}/${childTableName}/${childLinkId}`
  );

  store.dispatch(
    deleteChildTableLink({ matterId, fieldNamesToValidate, parentTableName, parentRowId, childTableName, childLinkId })
  );
}

/*****************************************
              SPECIAL TYPES
******************************************/

// Save changes to a field that is of a list type
export async function saveMatterListChanges(matterId, listTypeId, listValues, initialListValues, isCopying, fieldName) {
  const state = store.getState();
  const customerId = state.app.customerId;

  if (matterId) {
    const body = [
      { path: "/matterList_ListValues", op: "test", value: initialListValues ? initialListValues : [] },
      { path: "/matterList_ListValues", op: "replace", value: listValues },
    ];
    await fetchWrapper.patch(`customers/${customerId}/matters/${matterId}/lists/${listTypeId}`, body);
  }
  if (!isCopying) {
    store.dispatch(updateMatterListValues({ matterId, listTypeId, listValues, fieldName }));
  }
}

// Save one or more countries associated with a matter record
export async function saveMatterCountries(matterId, countryIsoCodes, initialCountryIsoCodes) {
  const state = store.getState();
  const customerId = state.app.customerId;

  const body = [
    { path: "/countries", op: "test", value: initialCountryIsoCodes ? initialCountryIsoCodes : [] },
    { path: "/countries", op: "replace", value: countryIsoCodes },
  ];

  await fetchWrapper.patch(`customers/${customerId}/matters/${matterId}/countries`, body);
  store.dispatch(updateMatterCountries({ matterId, countryIsoCodes }));

  updateOnNewRecordChange();
}

// save and upload a document to the server
export async function saveUploadedDocument(matterId, filename, base64stream) {
  const state = store.getState();
  const customerId = state.app.customerId;
  const body = {
    matterDocument_Base64: base64stream,
    matterDocument_Description:
      filename.lastIndexOf(".") === -1 ? filename : filename.substring(0, filename.lastIndexOf(".")),
    matterDocument_Filename: filename,
  };
  const response = await fetchWrapper.post(`customers/${customerId}/matters/${matterId}/documents`, body);

  store.dispatch(addNewChildObject({ matterId, tableName: "documents", newObject: response }));
  return response;
}

async function updateOnNewRecordChange() {
  const state = store.getState();
  if (state.matter.isNewRecord && state.matter.isComparing) {
    const matters = state.matter.records;
    const newRecords = [];
    for (let i = 0; i < matters.length; i++) {
      const matter = matters[i];
      const newRecord = await getMatterById(matter.id);
      newRecord.isPinned = matter.isPinned;
      newRecords.push({ ...matter, ...newRecord });
    }
    store.dispatch(setRecords(newRecords));
  }
}

export async function updateMatterOnCopy() {
  await store.dispatch(clearAllRecords());
  await store.dispatch(clearSelected());
}
