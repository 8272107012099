import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import SuperSelect from "components/global/SuperSelect";
import { getAllListDisplayValues, getListTypeByCode } from "utilities/lookup";

// Container Component for the display of a text field within the batch update system
function BatchUpdateFieldList({ field, onChange }) {
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [lookupOptions, setLookupOptions] = useState([]);

  const controlId = `field-select-${field?.fieldName}`;

  useEffect(() => {
    if (!field) return;
    setLookupOptions(getAllListDisplayValues(getListTypeByCode(field.fieldName)?.id));
  }, [field?.fieldName]);

  useEffect(() => {
    setSelectedOptionId(field?.newValue || null);
  }, [field?.newValue]);

  const handleSelectedOptionChange = (newValue) => {
    setSelectedOptionId(newValue);
    onChange(field, newValue);
  };

  return (
    <div className="td__edit-field-container">
      <SuperSelect
        id={controlId}
        options={lookupOptions}
        selectedOptionId={selectedOptionId}
        onChange={handleSelectedOptionChange}
        tabIndex={0}
        isMultiValued={field.isMultiValued}
        //sortValuesById={keyProps.field.sortValuesById}
      />
    </div>
  );
}

BatchUpdateFieldList.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
};

export default BatchUpdateFieldList;
