import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFormFields, setCompanyLinks } from "redux/batchUpdateSlice";
import { sectionTypes } from "utilities/constants";
import { defaultSectionOrder, sectionDefinitions } from "utilities/sectionDefinitions";
import { hasMatchingId } from "utilities/stringAndArray";
import PropTypes from "prop-types";
import { useState } from "react";
import { getCompanyLinkTypes } from "utilities/links";
import BatchUpdateTableRow from "./BatchUpdateTableRow";
import { getListTypeByCode } from "utilities/lookup";

// Displays a table that shows all fields that can be batch updated (e.g. form fields, company links, legal team)
const BatchUpdateTable = ({ tableName }) => {
  const dispatch = useDispatch();
  const batchUpdateState = useSelector((state) => state.batchUpdate);
  const localeState = useSelector((state) => state.locale);

  const [table, setTable] = useState([]);

  useEffect(() => {
    if (batchUpdateState.matterTypeIds === null) return;

    // Gather the form fields that match the matter types selected for batch update
    const formFieldsMatchingMatterTypes = [];
    // Iterate over each matterType in defaultSectionOrder
    defaultSectionOrder.forEach((order) => {
      const { matterTypeId, sectionOrder } = order;

      // Skip the current iteration if the matterTypeId does not match the batch matterTypeIds
      if (!hasMatchingId(batchUpdateState.matterTypeIds, matterTypeId)) return;

      // Iterate over each section ID in the sectionOrder array
      sectionOrder.forEach((sectionId) => {
        const section = sectionDefinitions.find((sd) => sd.id === sectionId && sd.type === sectionTypes.FORM);

        if (section) {
          // Add each field from the fieldList to the result, including the matterTypeId
          section.fieldList.forEach((field) => {
            let fieldName = field.fieldName;
            if (field.fieldName === "countries") fieldName = "matterCountry_Id";
            const isMultiValued = field.isListType && getListTypeByCode(field.fieldName)?.isMultiValued;

            // Check if the field already exists in the result array
            const existingField = formFieldsMatchingMatterTypes.find((f) => f.fieldName === fieldName);

            if (existingField) {
              // Add the current matterTypeId to the existing field's matterTypeIds array if it's not already included
              if (!existingField.matterTypeIds.includes(matterTypeId)) {
                existingField.matterTypeIds.push(matterTypeId);
              }
            } else {
              // Add the field as a new entry with the current matterTypeId
              formFieldsMatchingMatterTypes.push({
                fieldName: fieldName,
                matterTypeIds: [matterTypeId],
                isExpanded: false,
                isListType: field.isListType,
                isMultiValued,
              });
            }
          });
        }
      });
    });

    // Gather Company Links matching the selected record matter types
    let companyLinkTypesMatching = [];
    batchUpdateState.matterTypeIds.forEach((matterTypeId) => {
      const availableCompanyLinkTypes = getCompanyLinkTypes(matterTypeId);
      availableCompanyLinkTypes.forEach((linkType) => {
        companyLinkTypesMatching.push({ ...linkType, matterTypeId });
      });
    });

    // perform de-duplication of companyLinkTypesMatching using set
    companyLinkTypesMatching = Array.from(new Set(companyLinkTypesMatching.map((a) => a.id))).map((id) => {
      return companyLinkTypesMatching.find((a) => a.id === id);
    });

    // Now set the form fields, company links and legal team with the collected structure
    dispatch(setFormFields(formFieldsMatchingMatterTypes));
    dispatch(setCompanyLinks(companyLinkTypesMatching));
  }, [batchUpdateState.matterTypeIds]);

  useEffect(() => {
    if (!tableName) return;
    if (tableName === "formFields") setTable(batchUpdateState.formFields);
    else if (tableName === "companyLinks") setTable(batchUpdateState.companyLinks);
    else if (tableName === "legalTeam") setTable(batchUpdateState.legalTeam);
  }, [tableName, batchUpdateState.formFields, batchUpdateState.companyLinks, batchUpdateState.legalTeam]);

  const renderTable = () => {
    const renderTableHeader = () => {
      return (
        <tr>
          <th style={{ width: "3rem" }}></th>
          {tableName === "formFields" && <th>Field Name</th>}
          {tableName === "companyLinks" && <th>Link Type</th>}
          <th>Existing Value(s)</th>
          <th>Operation</th>
          <th>New Value</th>
        </tr>
      );
    };

    let tableTitle = "";
    if (tableName === "formFields") tableTitle = "Form Fields";
    else if (tableName === "companyLinks") tableTitle = "Company Links";
    else if (tableName === "legalTeam") tableTitle = "Legal Team";
    // else if (tableName === "commentInserts") tableTitle = "Comment Inserts";
    // else if (tableName === "imageInserts") tableTitle = "Image Inserts";
    // else if (tableName === "documentInserts") tableTitle = "Document Inserts";

    //console.log("formFields", batchUpdateState.formFields);
    //console.log("companyLinks", batchUpdateState.companyLinks);

    // Create a table with the form fields
    return (
      <>
        <div className="batch-update__table-title">{tableTitle}</div>
        <table className="batch-update__table">
          <thead>{renderTableHeader()}</thead>
          <tbody>
            {tableName === "formFields" &&
              batchUpdateState.formFields.map((field) => (
                <BatchUpdateTableRow key={field.fieldName} rowItem={field} tableName={tableName} table={table} />
              ))}
            {tableName === "companyLinks" &&
              batchUpdateState.companyLinks.map((cl) => (
                <BatchUpdateTableRow key={cl.id} rowItem={cl} tableName={tableName} table={table} />
              ))}
          </tbody>
        </table>
      </>
    );
  };

  return <div className="batch-update__table-container">{table?.length > 0 && renderTable()}</div>;
};

BatchUpdateTable.propTypes = {
  tableName: PropTypes.string,
};

export default BatchUpdateTable;
