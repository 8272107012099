import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "locale/dictionary";
import { getAllLookupValuesForSource } from "utilities/lookup";
import PopupHeader from "components/global/PopupHeader";
import Delete from "components/global/Delete";
import PropTypes from "prop-types";
import SuperSelect from "components/global/SuperSelect";
import { getMatterContacts } from "api/contact";
import { addContactLink, deleteContactLink, updateContactLink } from "redux/batchUpdateSlice";
import { findById } from "utilities/stringAndArray";

// Modal component for the editing of contact references to data rows
function BatchUpdateContactEditor({ isShowingPopup, tableName, parentRowItem, parentName, parentId, onClose }) {
  const legalTeam = useSelector((state) => state.customer.legalTeam);
  const appState = useSelector((state) => state.app);
  const batchUpdateState = useSelector((state) => state.batchUpdate);

  const dispatch = useDispatch();

  const [isAdding, setIsAdding] = useState(false);
  const [newContactId, setNewContactId] = useState(null);
  const [newRoleId, setNewRoleId] = useState(null);
  const [availableContacts, setAvailableContacts] = useState([]);

  useEffect(() => {
    let availableContactsLocal = [];
    if (tableName === "commentInserts") {
      setAvailableContacts(
        legalTeam.map((person) => {
          return {
            id: person.id,
            displayValue: `${person.givenName} ${person.familyName}`,
          };
        })
      );
    }
    if (tableName === "companyLinks" && parentId) {
      availableContactsLocal = getMatterContacts(appState.customerId, parentId).then((availableContactsRaw) => {
        setAvailableContacts(
          availableContactsRaw.map((person) => {
            return {
              id: person.id,
              displayValue: `${person.matterContact_GivenName} ${person.matterContact_FamilyName}`,
            };
          })
        );
      });
    }
  }, [parentId]);

  let roles = [];
  switch (tableName) {
    case "commentInserts":
      roles = getAllLookupValuesForSource("CommentContactLinkTypes");
      break;
    case "companyLinks":
      roles = getAllLookupValuesForSource("CompanyLinkContactLinkTypes");
      break;
    default:
      roles = getAllLookupValuesForSource("LegalTeamContactLinkTypes");
      break;
  }

  let popupHeaderTitle = "";
  let contactIdField = "";
  let roleIdField = "";
  if (tableName === "commentInserts") {
    contactIdField = "matterCommentContactLink_MatterContactId";
    roleIdField = "matterCommentContactLink_CommentContactLinkTypeId";
    popupHeaderTitle = `Contacts for Comment - ${parentName}`;
  } else if (tableName === "companyLinks") {
    contactIdField = "matterCompanyLinkContactLink_MatterContactId";
    roleIdField = "matterCompanyLinkContactLink_CompanyLinkContactLinkTypeId";
    popupHeaderTitle = `Contacts for Company - ${parentName}`;
  }
  if (popupHeaderTitle.length > 50) popupHeaderTitle = popupHeaderTitle.substring(0, 50) + "...";

  let numRows = parentRowItem?.contactLinks?.length;

  // const fieldsToValidate = keyProps.fieldList.filter((field) => field.fieldName === "contactLinks");
  // const fieldNamesToValidate = fieldsToValidate?.map((field) => field.fieldName);

  useEffect(() => {
    if (numRows === 0) {
      beginAddNew();
    }
  }, [numRows]);

  // Auto-save new row if both dropdowns have been filled out
  useEffect(() => {
    if (!newContactId || !newRoleId) return;
    const newObject = {
      [contactIdField]: newContactId,
      [roleIdField]: newRoleId,
    };

    dispatch(addContactLink({ parentRowItem, tableName, newObject }));
    setIsAdding(false);
  }, [newContactId, newRoleId]);

  const beginAddNew = () => {
    //const fieldList = [{ fieldName: contactIdField }, { fieldName: roleIdField }];
    const newAddRowData = { [contactIdField]: null, [roleIdField]: null }; // initializeAddingRowData(record, fieldList, nanoid());
    setNewContactId(newAddRowData[contactIdField]);
    setNewRoleId(newAddRowData[roleIdField]);
    setIsAdding(true);
  };

  const onSelectChange = (contactLinkId, fieldName, optionId) => {
    dispatch(
      updateContactLink({ contactLinkId, parentRowItem, tableName, fieldName, optionId, contactIdField, roleIdField })
    );
  };

  const onDeleteContact = (contactLinkId) => {
    dispatch(deleteContactLink({ contactLinkId, parentRowItem, tableName, contactIdField }));
  };

  const headerRender = (
    <tr className="data-panel__table-row">
      <td className="data-panel__table-header data-panel__table-header--40">{t("Name")}</td>
      <td className="data-panel__table-header data-panel__table-header--40">{t("Role")}</td>
      <td className="data-panel__table-header data-panel__table-header--10"></td>
    </tr>
  );

  const renderRows = parentRowItem?.contactLinks?.map((contact) => {
    return (
      <tr key={`contact-row-${contact[contactIdField]}`} className="data-panel__table-row">
        <td className="data-panel__table-cell data-panel__table-cell--40">
          <SuperSelect
            id={`contactEditorLegalTeam${contact[contactIdField]}`}
            options={availableContacts}
            selectedOptionId={contact[contactIdField]}
            onChange={(optionId) => onSelectChange(contact[contactIdField], contactIdField, optionId)}
          />
        </td>
        <td className="data-panel__table-cell data-panel__table-cell--40">
          <SuperSelect
            id={`contactEditorRoles${contact[contactIdField]}`}
            options={roles}
            selectedOptionId={contact[roleIdField]}
            onChange={(optionId) => onSelectChange(contact[contactIdField], roleIdField, optionId)}
          />
        </td>
        <td className="data-panel__table-cell data-panel__table-cell--action delete clickable">
          <Delete
            message="Delete this reference to this contact"
            onConfirm={() => onDeleteContact(contact[contactIdField])}
          />
        </td>
      </tr>
    );
  });

  const renderAddRow = isAdding ? (
    <tr className="data-panel__table-row">
      <td className="data-panel__table-cell data-panel__table-cell--40">
        <SuperSelect
          id="contactEditorContactAdd"
          options={availableContacts}
          selectedOptionId={newContactId}
          onChange={(optionId) => setNewContactId(optionId)}
        />
      </td>
      <td className="data-panel__table-cell data-panel__table-cell--40">
        <SuperSelect
          id="contactEditorRolesAdd"
          options={roles}
          selectedOptionId={newRoleId}
          onChange={(optionId) => setNewRoleId(optionId)}
        />
      </td>
      <td className="data-panel__table-cell data-panel__table-cell--5 clickable data-panel__save-row">&nbsp;</td>
    </tr>
  ) : (
    <tr>
      <td colSpan={3}>
        <button onClick={beginAddNew}>Add New</button>
      </td>
    </tr>
  );

  const renderContactsByRole = () => {
    const displayContactsByRoleArray = parentRowItem?.contactLinks?.map((contact) => {
      const contactId = contact[contactIdField];
      const roleId = contact[roleIdField];
      return `${findById(availableContacts, contactId)?.displayValue} (${findById(roles, roleId)?.displayValue})`;
    });
    return (
      <div className="contact-editor__selected-contacts">
        <div className="selected-contacts__header">{t("Selected Contacts:")}</div>
        <div className="selected-contacts__list">{displayContactsByRoleArray?.join(", ")}</div>
      </div>
    );
  };

  return isShowingPopup ? (
    <div className="modal-mask">
      <div className="contact-editor__popup">
        <PopupHeader title={popupHeaderTitle} onClose={onClose} />
        <div className="data-panel__table">
          <table className="data-panel__table-inner">
            <thead>{headerRender}</thead>
            <tbody>
              {renderRows}
              {renderAddRow}
              {numRows === 0 && !isAdding && (
                <tr>
                  <td>
                    <h4 className="data-panel__table-row">{t("No Contacts")}</h4>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ) : (
    parentRowItem?.contactLinks?.length > 0 && renderContactsByRole()
  );
}

BatchUpdateContactEditor.propTypes = {
  isShowingPopup: PropTypes.bool,
  tableName: PropTypes.string,
  parentRowItem: PropTypes.object,
  parentName: PropTypes.string,
  parentId: PropTypes.number,
  onClose: PropTypes.func,
};

export default BatchUpdateContactEditor;
