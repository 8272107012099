import { t } from "locale/dictionary";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";

// Control for displaying a boolean slider switch
function Switch({ isOn: isOnExternal, text, onSwitchChange, children, tabIndex }) {
  const [isOnInternal, setIsOnInternal] = useState(null);

  useEffect(() => {
    setIsOnInternal(isOnExternal);
  }, [isOnExternal]);

  const labelClassBase = "react-switch-label";
  const buttonClassBase = "react-switch-button";

  const labelClass = `${labelClassBase} ${labelClassBase}--${isOnInternal ? "on" : "off"}`;
  const buttonClass = `${buttonClassBase} ${buttonClassBase}--${isOnInternal ? "on" : "off"}`;

  const handleSwitchChange = () => {
    setIsOnInternal(!isOnInternal);
    onSwitchChange(!isOnInternal);
  };

  const handleClick = () => {
    handleSwitchChange();
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
      handleSwitchChange();
    }
  };

  return (
    <div className="switch-container" onClick={handleClick} onKeyDown={handleKeyDown} tabIndex={tabIndex}>
      {t(text)}
      <label className={labelClass} htmlFor="react-switch-new">
        <span className={buttonClass} />
      </label>
      &nbsp;{children}
    </div>
  );
}

Switch.propTypes = {
  isOn: PropTypes.bool,
  text: PropTypes.string,
  onSwitchChange: PropTypes.func,
  tabIndex: PropTypes.number,
};

export default Switch;
