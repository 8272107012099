import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { getDataTypeForField } from "utilities/datafield";
import { labelTypes } from "utilities/constants";
import BatchUpdateFieldDate from "./BatchUpdateFieldDate";
import BatchUpdateFieldLookup from "./BatchUpdateFieldLookup";
import BatchUpdateFieldBoolean from "./BatchUpdateFieldBoolean";
import BatchUpdateFieldText from "./BatchUpdateFieldText";
import { deleteInsertRow, updateInsertRow } from "redux/batchUpdateSlice";
import Delete from "components/global/Delete";
import { t } from "locale/dictionary";
import BatchUpdateContactEditor from "./BatchUpdateContactEditor";
import { useState } from "react";

const BatchUpdateInsertTableRow = ({ section, tableName, insertRow }) => {
  const dispatch = useDispatch();

  const [showContactSelectionPopup, setShowContactSelectionPopup] = useState(false);

  const handleFieldChange = (field, newValue) => {
    dispatch(updateInsertRow({ tableName, insertRow, fieldName: field.fieldName, newValue }));
  };

  const handleRowDelete = () => {
    dispatch(deleteInsertRow({ tableName, rowId: insertRow.id }));
  };

  const renderColumns = () => {
    return section?.fieldList
      ?.filter((field) => !field.isHiddenWhenAdding)
      .map((field) => {
        const fieldType = getDataTypeForField(field.fieldName);
        let fieldContent = null;
        if (field.fieldName === "contactLinks")
          fieldContent = (
            <>
              <BatchUpdateContactEditor
                isShowingPopup={showContactSelectionPopup}
                tableName={tableName}
                parentRowItem={insertRow}
                parentName={tableName === "commentInserts" ? "New Comment" : "n/a"}
                parentId={insertRow.id}
                onClose={() => {
                  setShowContactSelectionPopup(false);
                }}
              />
              <button onClick={() => setShowContactSelectionPopup(true)}>{t("Add Contacts")}</button>
            </>
          );
        else {
          switch (fieldType) {
            case labelTypes.LOOKUP: {
              fieldContent = <BatchUpdateFieldLookup field={field} onChange={handleFieldChange} rowItem={insertRow} />;
              break;
            }
            case labelTypes.DATE: {
              fieldContent = <BatchUpdateFieldDate field={field} onChange={handleFieldChange} rowItem={insertRow} />;
              break;
            }
            case labelTypes.BOOLEAN: {
              fieldContent = <BatchUpdateFieldBoolean field={field} onChange={handleFieldChange} rowItem={insertRow} />;
              break;
            }
            default: {
              fieldContent = <BatchUpdateFieldText field={field} onChange={handleFieldChange} rowItem={insertRow} />;
              break;
            }
          }
        }
        return <td key={field.fieldName}>{fieldContent}</td>;
      });
  };

  return (
    <tr>
      {renderColumns()}
      <td>
        <Delete message={t("Remove this row")} onConfirm={handleRowDelete} />
      </td>
    </tr>
  );
};

BatchUpdateInsertTableRow.propTypes = {
  insertRow: PropTypes.object,
  section: PropTypes.object,
  tableName: PropTypes.string,
};

export default BatchUpdateInsertTableRow;
