import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaUpload } from "react-icons/fa";
import PropTypes from "prop-types";
import { t } from "locale/dictionary";
import { stringArrayBufferToBase64 } from "utilities/stringAndArray";
import { useDispatch, useSelector } from "react-redux";
import { saveDocumentData, saveImageData } from "redux/batchUpdateSlice";

let tempIdCounter = 1;

function BatchUpdateFileUploader({ isImage, isHeaderControl, children, onAddNew }) {
  const batchUpdateImages = useSelector((state) => state.batchUpdate).imageInserts;

  const dispatch = useDispatch();

  const [numExistingImages, setNumExistingImages] = useState(0);

  const onDrop = useCallback(
    (acceptedFiles) => {
      let newSortIndex = numExistingImages;
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          const base64String = stringArrayBufferToBase64(reader.result);
          if (isImage) {
            newSortIndex++;
            dispatch(
              saveImageData({
                imageId: tempIdCounter++,
                fileName: file.name,
                description: file.name,
                base64: base64String,
                sortIndex: newSortIndex,
              })
            );
          } else
            dispatch(
              saveDocumentData({
                documentId: tempIdCounter++,
                fileName: file.name,
                description: file.name,
                base64: base64String,
              })
            );
        };
        reader.readAsArrayBuffer(file);
      });
    },
    [numExistingImages]
  );

  useEffect(() => {
    if (batchUpdateImages) setNumExistingImages(batchUpdateImages.length);
  }, [batchUpdateImages]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: isHeaderControl });

  const outerClassNameBase = "file-uploader";
  let outerClassName = outerClassNameBase;

  if (isDragActive) outerClassName += ` ${outerClassNameBase}--is-dragging`;

  return (
    <div className={outerClassName} {...getRootProps()}>
      <input {...getInputProps()} />
      {isImage ? (
        <div className="flex-column-center">
          <button className="flex-row-center">
            {t("UPLOAD IMAGE(S)")}&nbsp; &nbsp;
            <FaUpload />
          </button>
          <p>{t("Or drop image(s) into this panel")}</p>
        </div>
      ) : (
        <div className="flex-column-center">
          <button className="flex-row-center">
            {t("UPLOAD FILE(S)")}&nbsp; &nbsp;
            <FaUpload />
          </button>
          <p>{t("Or drop file(s) into this panel")}</p>
        </div>
      )}
    </div>
  );
}

BatchUpdateFileUploader.propTypes = {
  isImage: PropTypes.bool,
  isHeaderControl: PropTypes.bool,
  children: PropTypes.element,
  setSelectedIndexLast: PropTypes.func,
  onAddNew: PropTypes.func,
};

export default BatchUpdateFileUploader;
